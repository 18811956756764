
  import userController from '/home/builduser/work/189599a6bfa34d79/comments-web/comments-widget/src/components/CommentsWidget/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://c098db2425c540d3982e073ae7fc4c9a@sentry.wixpress.com/3531',
    id: 'c098db2425c540d3982e073ae7fc4c9a',
    projectName: 'comments-widget',
    teamName: 'forum',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["wix-comments"],"centralized":true};

  var translationsConfig = {"enabled":true,"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/189599a6bfa34d79/comments-web/comments-widget/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var biConfig = {"enableUniversalEvents":true};

  var defaultTranslations = {"markLabel.bestAnswer.label":"Best Answer","markLabel.bestAnswer.markAction":"Mark as Best Answer","markLabel.bestAnswer.unmarkAction":"Unmark as Best Answer","markLabel.solved.label":"Solved","markLabel.solved.markAction":"Mark as Solved","markLabel.solved.unmarkAction":"Unmark as Solved","markLabel.featured.label":"Featured","markLabel.featured.markAction":"Mark as Featured","markLabel.featured.unmarkAction":"Unmark as Featured","markLabel.pinned.label":"Pinned","markLabel.pinned.markAction":"Mark as Pinned","markLabel.pinned.unmarkAction":"Unmark as Pinned","markLabel.custom.markAction":"Mark as {customLabel}","markLabel.custom.unmarkAction":"Unmark as {customLabel}","demoContent.comment01":"Your site visitors can leave comments like this one to create a conversation around your site’s content. Some can be just plain text and some can contain images, gifs, videos and other embedded content.","demoContent.comment02":"Comments can be as short or as long as your visitors would like.","demoContent.reply01":"Other visitors can also reply to comments.","demoContent.reply02":"There are even replies to a reply.","demoContent.replierName":"Replier Name","demoContent.commenterName":"Commenter Name","demoContent.alert":"These are demo comments. They will not be shown live when you publish your site.","settings.textTab.headerTitleTextDefault":"Comments","settings.textTab.headerTitleText":"{count, plural, =0 {{title}} other {{title} ({count})}}","settings.textTab.placeholderTextDefault":"Write a comment","settings.textTab.publishButtonTextDefault":"Publish","settings.textTab.likeButtonTextDefault":"Like","settings.textTab.replyButtonTextDefault":"Reply","settings.settingsTab.ratingSelectorTextDefault":"Add a rating","settings.textTab.adminBadgeTextDefault":"Admin"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/189599a6bfa34d79/comments-web/comments-widget/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Wix Comments",
    componentName: "CommentsWidget",
    appDefinitionId: "91c9d6a7-6667-41fb-b0b4-7d3b3ff0b02e",
    componentId: "1c154801-444a-49ad-aee8-325ff74f89b1",
    projectName: "comments-widget",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "components/CommentsWidget/locales/widget"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/189599a6bfa34d79/comments-web/comments-widget/src/components/CommentsWidget/controller.ts",
  };

  export const controller = _controller
  export default controller;
